import type { NextPage } from 'next'

import { SanityPage } from 'src/components/layouts/Page'
import { Home } from 'src/components/organisms/Home'

const HomePage: NextPage = () => {
  return (
    <SanityPage isFullWidth slug='home' isIntercomEnabled>
      {Home}
    </SanityPage>
  )
}

export default HomePage
